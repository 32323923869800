import StepEdit from "./StepEdit";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import LoadingSpinner from "./LoadingSpinner";

export default function OneStepView({ steps, setSteps }) {

  const videoRef = useRef(null); // Create a ref to control the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track if video is playing
  const [isMuted, setIsMuted] = useState(true); // Initialize mute state to true (since default is muted)
  const [isLoading, setIsLoading] = useState(false);

  // Toggle play and pause
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  // Toggle mute and unmute
  const toggleMuteUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted); // Toggle mute/unmute state
    }
  };

  // Fullscreen function
  const handleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) { /* Safari */
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) { /* IE11 */
      videoRef.current.msRequestFullscreen();
    }
  };

  // Additional states and hooks
  const axiosPrivate = useAxiosPrivate();
  const [userInfo, setUserInfo] = useState({ roles: "" });
  const [userId, setUserId] = useState("");
  const ONEUSER_URL = `/users/${userId}`;
  const [autoplay, setAutoplay] = useState("");
  const [automute, setAutomute] = useState("");
  const [loop, setLoop] = useState("");

  useEffect(() => {
    if (userId !== "undefined") {
      axiosPrivate.get(ONEUSER_URL).then(res => {
        setUserInfo(res.data[0]);
        setAutoplay(res.data[0].autoplay);
        setAutomute(res.data[0].automute);
        setLoop(res.data[0].loop);

        // Initialize mute state based on the automute value from the backend
        if (res.data[0].automute !== undefined) {
          setIsMuted(res.data[0].automute);
        }
      })
      .catch(err => {
        console.error("Failed to fetch user data:", err);
        // Optionally set an error state here
      });
    }
    
  }, [userId, axiosPrivate]);

  const { auth } = useAuth();
  let { stepIdParam } = useParams();
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setPosition(steps.findIndex(step => step._id === Number(stepIdParam)));
  }, [steps, stepIdParam]);

  const navigate = useNavigate();
  const location = useLocation();

  if (steps[position]) {
    return (
      <section className="section-one-step-view">
        <button className="close close-one-view" onClick={() => navigate(-1)}>&times;</button>

        <div className="one-step-view">
          <div className="one-step-view-header">{steps[position].step_title}</div>

          {steps[position].step_pronunciation !== '' ? (
            <div className="one-step-view-audio">
              <audio className="audio" src={steps[position].step_pronunciation} controls />
            </div>
          ) : (
            <div className="no-media-file">We don't have yet an audio file for this step's pronunciation.</div>
          )}

          <div className="one-step-view-video">
            {steps[position].step_video !== '' ? (
              <>
              {isLoading && <LoadingSpinner/>}
                <video
                  className="video"
                  ref={videoRef} // Attach ref to the video element
                  title={`${steps[position].step_title} video`}
                  autoPlay={autoplay ? true : false}
                  loop={loop ? true : false}
                  preload="auto"
                  muted={automute ? true : false}
                  src={steps[position].step_video}
                  controls={false}
                  onLoadStart={() => setIsLoading(true)}
                  onLoadedData={() => setIsLoading(false)} 
                ></video>

                {/* Custom controls outside the video */}
                <div className="video-controls">
                  <div className="outside-video-controls">
                    {/* Play/Pause Button */}
                    <div className="outside-video-control-button" onClick={togglePlayPause}>
                      <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                    </div>

                    {/* Mute/Unmute Button */}
                    <div className="outside-video-control-button" onClick={toggleMuteUnmute}>
                      <i className={`fas ${isMuted ? 'fa-volume-up' : 'fa-volume-mute'}`}></i>
                    </div>

                    {/* Fullscreen Button */}
                    <div className="outside-video-control-button" onClick={handleFullscreen}>
                      <i className="fas fa-expand"></i>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-media-file">We don't have yet a video file for this step.</div>
            )}
          </div>

          {auth.roles && auth.roles.includes(2006) ? (
            <StepEdit steps={steps} stepIdParam={stepIdParam} setSteps={setSteps} />
          ) : null}
        </div>
      </section>
    );
  }

  return null; // Return null if `steps[position]` doesn't exist.
}
