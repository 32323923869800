import Register from './components/Register';
import Login from './components/Login';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Home from './components/Home'
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import LevelBeginner from './components/LevelBeginner';
import Level_Course from './components/LevelCourse';
import LevelRueda2 from './components/LevelRueda2';
import LevelIntermediate from './components/LevelIntermediate';
import LevelIntermediate2 from './components/LevelIntermediate2';
import LevelAdvanced from './components/LevelAdvanced';
import LevelAdvanced2 from './components/LevelAdvanced2';
import Steps from './components/Steps';
import Users from './components/Users';
import Navbar from './components/Navbar';
import FileUpload from './components/FileUpload';
import OneStepView from './components/OneStepView';
import Hamburger from './components/Hamburger';
import { MenuProvider } from './context/MenuContext';

import StepAdd from './components/StepAdd';
// import SongTest from './components/SongTest';
// import SongForm from './components/SongForm/index';

import RecyclingSteps from './components/RecyclingSteps';
import RecyclingUsers from './components/RecyclingUsers';
import Profile from './components/Profile'
import ResetPassword from './components/ResetPassword';


import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { axiosPrivate } from './api/axios';





const ROLES = {
  'Beginner': 2001,
  'Intermediate': 2002,
  'Intermediate2': 2003,
  'Advanced': 2004,
  'Advanced2': 2005,
  'Admin': 2006
}


function App() {


  const navigate = useNavigate();
  const location = useLocation();

  const [steps, setSteps] = useState();
  // console.log("app steps", steps)

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController(); //Supported by axios.

    const getSteps = async () => {
      try {
        const response = await axiosPrivate.get('/steps', {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setSteps(response.data);
      } catch (err) {
        console.error(err)
      }
    }

    getSteps();
    // console.log(steps)

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [
    // axiosPrivate, location, navigate
  ])

  useEffect(() => {
    setSteps(steps)
  }, [steps])

  const [showNavbar, setShowNavbar] = useState(false);

  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (

    <MenuProvider>
    {/* <Hamburger/> */}
      {/* <Navbar /> */}

      <Routes>


        <Route path="/" element={<Layout showNavbar={showNavbar} toggleNavbar={toggleNavbar}/>}>
          {/* public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="linkpage" element={<LinkPage />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Home />} />
          <Route path="/fileupload" element={<FileUpload />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          {/* <Route path="/users/:userId" element={<Profile />} /> */}

          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>

            <Route element={<RequireAuth allowedRoles={[ROLES.Beginner]} />}>
              <Route path="/beginner" element={<LevelBeginner steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Beginner]} />}>
              <Route path="/steps/:stepIdParam" element={<OneStepView steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Intermediate]} />}>
              <Route path="/intermediate" element={<LevelIntermediate steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Intermediate2]} />}>
              <Route path="/intermediate2" element={<LevelIntermediate2 steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Advanced]} />}>
              <Route path="/advanced" element={<LevelAdvanced steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Advanced2]} />}>
              <Route path="/advanced2" element={<LevelAdvanced2 steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Beginner]} />}>
              <Route path="/course" element={<Level_Course steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Beginner]} />}>
              <Route path="/rueda2" element={<LevelRueda2 steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Intermediate]} />}>
              <Route path="editor" element={<Editor />} />
            </Route>


            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="admin" element={<Admin steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="admin/steps" element={<Steps steps={steps} setSteps={setSteps} />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="admin/users" element={<Users />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/steps/add"
                element={
                  <StepAdd />
                  // <SongTest />
                  // <SongForm />
                } />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/steps/recycling" element={<RecyclingSteps />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/users/recycling" element={<RecyclingUsers />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Beginner]} />}>
      <Route path="/users/:userId" element={<Profile />} />
    </Route> 


          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>



      
    </MenuProvider>




  );
}

export default App;