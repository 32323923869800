
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

//Components

import UserEdit from "./UserEdit";
import UserEditPassword from "./UserEditPassword";
import CheckboxUsers from "./CheckboxUsers";
import ConfirmRecycleAllSelectedUsers from "./ConfirmRecycleAllSelectedUsers";
import UserEditName from "./UserEditName";
import UserLevelQuickEdit from "./UserLevelQuickEdit";

//Styling
import "./styling/scroll-table.css"
import SearchbarUsers from "./SearchbarUsers";

const julioID = 2;
const manuelID = 1;

const Users = () => {
    const [users, setUsers] = useState();







    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    //  console.log(users)

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/users', {
                    signal: controller.signal
                });


                // console.log("res data", response.data);
                // const userNames = response.data.map(user => user.username)
                // const userIds = response.data.map(user => user._id)


                //console.log(response.data); //Comment out production

                const sortedArray = response.data.slice().sort((a, b) => {
                    const getLastNumber = (obj) => {
                        const roles = obj.roles;
                        const roleKeys = Object.keys(roles);
                        const numbers = roleKeys.map(key => roles[key]);
                        return Math.max(...numbers);
                    };

                    return getLastNumber(a) - getLastNumber(b);
                });



                isMounted && setUsers(sortedArray);
            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate])
    //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

    //   function deleteUser(userId) {
    //     // console.log("user id", userId)
    //     const headers = {
    //       'userId': userId
    //     };

    //     axiosPrivate.delete('/users/delete/', { headers })
    //       .then(() => setUsers(users.filter(user => user._id !== userId)))
    //   }

    return (
        <section className="section-bigger section-admin">
            <div
                onClick={() => { navigate('/admin', { state: { from: location }, replace: true }) }}
                className="title-pointer text-align-center level-title"
            >Administrator</div>
            <div className='text-align-center title-pointer'>Manage Users</div>
{/* <div className="show-less-500">This section works better in wider screens. <br></br>Rotate your device for a better view. </div> */}

            {/* <div className="delete-selection-recycling-bin">
                <ConfirmRecycleAllSelectedUsers setUsers={setUsers} />
                {console.log(users)}
            </div> */}

<SearchbarUsers setUsers={setUsers}/>

            {users?.length
                ? (

                    
                      

                            <div>

                                <div className="delete-selection-recycling-bin">
                                    <ConfirmRecycleAllSelectedUsers setUsers={setUsers} />
                                    <button
                                        className="delete-selection btn-no-btn"
                                        title="Go to recycling bin"
                                        onClick={() => { navigate('/users/recycling', { state: { from: location }, replace: true }) }}><i className="fa-solid fa-trash-can"></i></button>

                                </div>
                                
                                
                                    <div >
                                        {users.map((user) =>

                                            <div className="admin-one-user" key={user._id}>
                                                <div className="users-checkbox-name margin-padding-zero">
                                                <div>{user._id !== julioID && user._id !== manuelID && <CheckboxUsers user={user} setUsers={setUsers} />}</div>
                                                <div>{
                                                    <UserEditName user={user} users={users} setUsers={setUsers} />}</div>
                                                    </div>



                                                <div><UserLevelQuickEdit user={user} users={users} setUsers={setUsers} /></div>

                                         

                                                <div className="users-contact margin-padding-zero">
                                                <div>{<UserEdit user={user} users={users} setUsers={setUsers} />}</div>

                                                <div><UserEditPassword user={user} users={users} setUsers={setUsers} /></div>
                                                <div>

                                                    <a href={`mailto:${user.email}`}>
                                                        <button
                                                            className="btn-no-btn"
                                                            title="Send email"
                                                        ><i className="fa-solid fa-envelope"></i></button>
                                                    </a>
                                                </div>

                                                </div>
                                            </div>)}
                                    </div>
                               
                            </div>
                       

                       

                ) : <p>No users to display</p>
            }
        </section>
    );
};

export default Users;
