//Represents all the children of the layout component. Anything nested in the Layout component is represented by the outlet. That allows us to apply more things to our app (header, footer...). We can also have multiple outlets.

import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import { useMenuContext } from '../context/MenuContext';
import Hamburger from './Hamburger';
import { useState, useEffect } from 'react';


// export default function Layout () {
//   return (
//     <main className="App">
//       <Outlet />
//     </main>
//   )
// }

export default function Layout() {

  const { showNavbar, toggleNavbar } = useMenuContext();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  // Ensure showNavbar is false on mobile view initially
  useEffect(() => {
    if (isMobileView && showNavbar) {
      toggleNavbar(); // Close the navbar if in mobile view on mount
    }
  }, [isMobileView]);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`App layout ${showNavbar ? 'layout-shifted' : ''}`}>
      {isMobileView && !showNavbar && <Hamburger toggleNavbar={toggleNavbar} />}
      {!isMobileView && !showNavbar && <Hamburger toggleNavbar={toggleNavbar} />}
      {showNavbar && <Navbar />}

      {showNavbar && <button onClick={toggleNavbar} className="close-bigger-navbars">x</button>}
      <Outlet />




    </div>
  )
}